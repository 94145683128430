
@import '../assets/styles/variables.scss';
.instance-picker {
  margin: 0;

  .option__title {
    padding-left: 5px;
    .uk-text-small {
      font-size: 0.75rem;
    }

    .uk-text-muted {
      color: gray !important;
    }
  }

  .multiselect-options {
    bottom: 56px;
    width: 188px;
  }

  .instance-picker-multiselect {
    max-width: 196px;
  }

  .multiselect,
  .multiselect-wrapper,
  .multiselect__single {
    font-size: 12px;
  }

  .multiselect-wrapper {
    min-height: 58px !important;
    background-color: #e2e8f0 !important;
    justify-content: space-between;
  }

  .multiselect-search {
    width: 100%;
    border: 0;
    padding: 8px 35px 8px 14px;
    outline: none;
    background: transparent;
    font-size: 16px;
    font-family: inherit;
    position: absolute;
    input {
      padding-left: 5px !important;
      font-size: 13px !important;
    }
    &:focus {
      outline: none !important;
      outline-color: transparent !important;
    }
  }
  .multiselect-option {
    color: #35495e !important;
    padding-top: 9px;
    padding-right: 12px;
    padding-bottom: 9px;
    padding-left: 12px;
    &.is-selected {
      color: #fff !important;
      background: #46ade5 !important;
      .uk-text-muted {
        color: #fff !important;
      }
    }
  }

  .multiselect-option.is-pointed,
  .multiselect-option.is-selected.is-pointed {
    color: #fff !important;
    background: #039be5 !important;
    .uk-text-muted {
      color: #fff !important;
    }
  }

  .multiselect-dropdown {
    width: 188px;

    &.is-top {
      border-radius: 0 0 0 0;
    }
  }

  .multiselect__placeholder {
    padding-left: 5px;
  }

  .multiselect-caret {
    margin-left: auto;
  }
}
