
@import '../../assets/styles/variables.scss';
.big-nav-toggle {
  position: fixed;
  top: 80px;
  left: 191px;
  border: 1px solid $gray-100;
  background-color: $gray-300;
  border-radius: 25px;
  padding: 8px;
  &:hover {
    background-color: $blue-600;
    color: $white; } }

.small-nav-toggle {
  background-color: $white;
  border-radius: 25px;
  padding-left: 1px;
  color: $blue-800;
  &:hover {
    background-color: $blue-600;
    color: $white; } }

.left-nav-wrap-large {
  margin-top: 30px; }

.language-active {
  cursor: not-allowed;
  color: $black; }

.language-active:hover {
  cursor: not-allowed;
  color: $black; }

.language-inactive {
  color: $blue-600; }

.language-inactive:hover {
  color: $blue-600; }

.language-switcher {
  span {
    margin: 0 3px 0 3px; } }

.subnav {
  margin-left: 30px; }
