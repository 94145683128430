@import '../../../node_modules/nprogress/nprogress.css';

@import url('https://cdnjs.cloudflare.com/ajax/libs/chartist/0.11.0/chartist.min.css');

// 1. Your custom variables and variable overwrites.
@import 'variables';
@import './fonts.sass';
@import './vue-treeselect.min.css';

// 2. Import default variables and available mixins.
@import '../../../node_modules/uikit/src/scss/variables.scss';
@import '../../../node_modules/uikit/src/scss/mixins.scss';

// 3. Your custom mixin overwrites.

// 4. Import UIkit.
@import '../../../node_modules/uikit/src/scss/uikit.scss';

// Custom styles

.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  margin-bottom: 20px; }


.uk-button {
  margin-right: 4px; }


.pointer {
  cursor: pointer; }

.green {
  color: green; }

.red {
  color: red; }


.uk-card-selected {
  background: $card-selected-background;
  border-color: $card-selected-border-color; }


.uk-badge {
  margin: 4px 2px;
  border-radius: $border-rounded-border-radius;
  box-shadow: $global-medium-box-shadow; }

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
  border: none;
  &.is-active {
    box-shadow: 0 0 0 0;
    border: none; } }


.multiselect__tags {
  background: $gray-100;
  border: none;
  //-webkit-border-radius: none
  //-moz-border-radius: none
 }  //border-radius: none

.multiselect__input {
  background: $gray-100;
  margin-top: 15px; }


.multiselect-remove-tag {
  cursor: pointer;
  padding: 1px;
  margin-left: 3px;
  color: $blue-800;
  border-radius: 50%; }


.multiselect-remove-tag:hover {
  background: rgba(255,255,255,0.05); }

.avatar {
  object-fit: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $white;
  margin: 0 6px; }

.avatar-sm {
  width: 20px;
  height: 20px; }

.avatar-xl {
  width: 100px;
  height: 100px; }


.uk-card-selected-active {
  background-color: $blue-100;
  border: 1px solid $blue-500 !important; }


.uk-card-selected-inactive {
  background: rgba(240, 245, 251, 0.4);
  border-color: rgba(208, 223, 241, 0.4);
  box-shadow: none;

  h3,
  p,
  .uk-icon {
    opacity: 0.4; } }



.uk-card-active {
  @extend .uk-card-default; }


.uk-card-inactive {
  @extend .uk-card-default;
  opacity: 0.4;
  box-shadow: none; }


.uk-input-disabled {
  cursor: not-allowed; }


/* HNUSNY CSS. Odstranit pri implementaci. Rychly fixy, abych nemusel resit kompilovani component. */
.uk-child-width-1-8 > div:nth-child(2),
.uk-child-width-1-7 > div:nth-child(1) {
  width: 30%; }


.uk-child-width-1-8 > div:nth-child(1) {
  width: 5%; }


.uk-child-width-1-8 > *,
.uk-child-width-1-7 > * {
  padding: 0 5px; }


.ct-series-a .ct-line,
.ct-series-a .ct-point {
  stroke: $green-500; }


.ct-series-b .ct-line,
.ct-series-b .ct-point {
  stroke: $red-500; }


.ct-series-c .ct-line,
.ct-series-c .ct-point {
  stroke: $teal-500; }


.ct-series-d .ct-line,
.ct-series-d .ct-point {
  stroke: $purple-500; }


.point-1,
.point-2,
.point-3,
.point-4,
.point-5,
.point-6 {
  display: inline-block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-top: 2.5px; }


.point-1 {
  background-color: $green-500; }


.point-2 {
  background-color: $red-500; }


.point-3 {
  background-color: $teal-500; }


.point-4 {
  background-color: $purple-500; }


.point-5 {
  background-color: #453d3f; }

.point-6 {
    background-color: #59922b; }



#content .uk-text-primary {
  color: $blue-800 !important; }


.uk-button {
  padding: 0 15px; }


.uk-card-small h3 {
  margin: 0; }


h3 {
  font-size: 1.25rem;
  line-height: 1.4; }


.uk-grid-small {
  margin-left: 0; }


.uk-grid-small > * {
  padding-left: 0; }


.uk-grid-small p {
  margin-top: 0;
  margin-bottom: 0; }


.uk-content-container {
  border-radius: 5px;
  border: 1px solid $gray-200;
  box-shadow: 0 2px 1px $gray-200;
  background-color: $white; }


.scrollable-content {
  overflow-y: visible;
  overflow-x: hidden; }


/* FONTS */
h1,
.uk-h1 {
  font-weight: 700;
  font-size: 2rem; }


h2,.uk-h2 {
  font-weight: normal;
  font-size: 1.75rem; }


.uk-font-weight-500 {
  font-weight: 500 !important; }


//:not(pre) > code, :not(pre) > kbd, :not(pre) > samp, .code
//  color: inherit
//  font-family: Consolas, monaco, monospace
//  font-size: 0.875rem
//  white-space: pre


.uk-nowrap {
  white-space: nowrap; }


.uk-pre-wrap {
  white-space: pre-wrap;
  word-wrap: break-word; }


/* GRID */
.uk-grid .uk-card:not(:first-of-type) {
  margin-top: 10px; }


.uk-card-small.uk-card-body, .uk-card-small .uk-card-body {
  margin-bottom: 10px; }


/* TABLE */
.uk-table td {
  vertical-align: middle; }


.uk-table th {
  font-weight: 500; }


.uk-table-hover > tr:hover > td, .uk-table-hover tbody > tr:hover > td {
  background: $blue-100; }


/* FORMS */

textarea {
  resize: none; }


h6 .uk-input {
  padding-left: 5px;
  height: 30px; }


.uk-input, .uk-textarea {
  background: $white;
  border: 1px solid $gray-500;
  border-radius: $border-rounded-border-radius;
  color: $gray-900;
  transition: all 0.3s ease; }


.uk-input::placeholder {
  color: $gray-600; }


input.uk-form-width-xsmall {
  width: 60px; }


.uk-input:focus, .uk-select:focus, .uk-textarea:focus, .uk-offcanvas-bar .uk-textarea:focus {
  background: $white;
  border: 2px solid;
  border-color: $blue-600;
  box-shadow: $global-inset-small-box-shadow; }

.uk-input.uk-form-danger, .uk-input.uk-form-danger:focus, .uk-textarea.uk-form-danger, .uk-textarea.uk-form-danger:focus,
.uk-offcanvas-bar .uk-input.uk-form-danger, .uk-input.uk-form-danger:focus, .uk-textarea.uk-form-danger, .uk-textarea.uk-form-danger:focus {
  border-color: $global-danger-background !important;
  color: #1a202c; }


.uk-form-stacked .uk-form-label {
  margin-bottom: 5px; }


.uk-input.uk-disabled {
  background: $white;
  border: 1px solid  #a0aec0 !important;
  color: $gray-500; }


.uk-input:disabled {
  background: $white;
  cursor: not-allowed; }


.uk-input.uk-disabled::placeholder,
.uk-input:disabled::placeholder {
  color: $gray-500; }


/* CARDS */

.uk-card-default, .uk-card-active, .uk-card-inactive {
  color: $black; }


.uk-card {
  border: 1px solid $gray-200;
  border-radius: $border-rounded-border-radius; }


.uk-card-selected {
  background-color: $blue-600;
  border-color: $blue-700; }


.uk-radio, .uk-checkbox {
  margin-top: 2px;
  background: none no-repeat;
  border: 1px solid $gray-500; }


.uk-checkbox:checked {
  background-position: 50%; }


.uk-card-small.uk-card-body, .uk-card-small .uk-card-body {
  padding: 15px 15px; }


/* BUTTONS */

button {
  outline: none; }

.uk-button {
  font-size: inherit;
  text-align: center;
  text-decoration: none;
  border-radius: $border-rounded-border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  font-weight: 400;
  line-height: 1;
  padding: 12px 15px;
  border: 1px solid;
  height: 40px /* ugly fix because some buttons have spans in them which screws with height. Fix: remove spans in buttons? 8.12.2019. Let's see... :) */; }

.uk-button-large {
  padding: 0 40px;
  line-height: 55px;
  font-size: 1.25rem;
  height: 57px; }

.uk-button-small {
  padding: 7px 15px;
  height: 30px /* ugly fix because some buttons have spans in them which screws with height. */; }


.uk-button-text {
  text-shadow: none;
  -webkit-box-shadow: none; }


.uk-button-primary {
  background-color: $blue-600;
  color: $white; }


.uk-button-success {
  background-color: $green-600;
  color: $white;

  &:hover {
    background-color: $green-700; } }



.uk-button-danger {
  background-color: $red-600;
  color: $white;

  &:hover {
    background-color: $red-700; } }



.uk-button-default {
  background-color: $gray-100;
  color: $gray-800;
  box-shadow: none;
  border: 1px solid $gray-300 !important; }


.uk-button-default:hover {
  border-color: $gray-400; }


.uk-button-secondary {
  background-color: $white;
  color: $gray-800;
  border: 1px solid $gray-200; }


.uk-button-secondary:hover {
  background-color: $gray-100;
  color: $gray-800;
  border: 1px solid $gray-300; }


.uk-button-default:disabled {
  cursor: not-allowed; }


.uk-disabled {
  box-shadow: none;
  cursor: not-allowed;
  border-color: rgba(0,0,0,0); }




.copy-abs {
  top: 1px;
  right: 1px;
  color: $gray-800;
  cursor: pointer; }


.copy-abs:hover {
  background-color: $white; }


.copy-abs:active {
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.08); }


/* DROPDOWN */
.uk-dropdown {
  box-shadow: $global-xlarge-box-shadow; }



/* NOTIFICATIONS */
.uk-notification {
  z-index: $notification-z-index; }


.uk-notification-message {
  box-shadow: $global-xlarge-box-shadow;
  background: $white; }


/* PAGINATION */
.uk-pagination {
  margin-top: 10px; }

.uk-pagination>* {
  padding-left: 5px; }

.uk-pagination>*>* {
  padding: 0 10px; }


/* MODAL */
.uk-modal {
  z-index: $modal-z-index; }


/* HELPERS */
.uk-margin-xsmall-right {
  margin-right: 4px; }


/* DATAGRID */
.datagrid-filter-button {
  border: none; }


.datagrid-filter {
  border-top-right-radius: $border-rounded-border-radius;
  border-bottom-right-radius: $border-rounded-border-radius; }


/* -- Top navigation -- */
#top-head {
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid $gray-900; }


/* Smaller Header */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* navbar height */
  height: 52px;
  padding: 0 8px;
  font-size: 0.85rem; }


#content .uk-nav > li > a {
  padding: 5px; }

.uk-nav {
  font-size: 12px; }

.uk-nav > li > a {
  padding: 0; }


.navbar-logo {
  background-color: $black;
  margin-left: -12px; }


.nav-bottom {
  position: absolute;
  bottom: 0;
  width: 100%; }


/* Left bar */
#left-col {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-x: visible;
  overflow-y: visible;
  background-color: $gray-300;
  color: $gray-900;
  z-index: 8;
  transition: width 0.1s ease-out;

  .multiselect {
    margin-top: 5;
    border: none;
    margin-top: 0; }

  .multiselect__tags {
    padding-top: 10px;
    padding-left: 0; }

  .multiselect__input, .multiselect__tags, .multiselect__single {
    background: $gray-300;
    color: $blue-600; }

  .multiselect__input::placeholder {
    color: $blue-600; }

  .multiselect__single {
    color: $blue-600; }

  .multiselect__option {
    padding: 8px; }

  .multiselect > * {
    font-size: 13px; } }


#product-detail {
  .multiselect__option {
    white-space: normal !important; } }

.uk-light .router-link-active {
  color: $gray-900 !important; }

.largeMenu #left-col {
  width: 210px; }


.smallMenu #left-col {
  width: 50px; }


.smallMenu .hideOnSmallMenu {
  display: none; }


.smallMenu #left-col ul.uk-nav-default li.uk-nav-header {
  padding: 9px; }


.smallMenu #left-col ul.uk-nav-default > li {
  padding: 0;
  margin: 14px 0 14px 14px; }


#left-col ul.uk-nav-default li .uk-nav-sub {
  display: none; }


#left-col ul.uk-nav-default li:hover .uk-nav-sub {
  display: block;
  position: absolute;
  padding: 15px;
  bottom: 0;
  background: #e2e8f0;
  min-width: 200px;
  box-shadow: $global-xlarge-box-shadow;
  border-top-right-radius: $border-rounded-border-radius;
  border-bottom-right-radius: $border-rounded-border-radius; }


.smallMenu #left-col ul.uk-nav-default li:hover .uk-nav-sub {
  left: 34px; }


.largeMenu #left-col ul.uk-nav-default li:hover .uk-nav-sub {
  left: 208px; }



/* Left bar links */
#left-col ul.uk-nav-default > li {
  padding: 8px 20px;
  border-left: 2px solid transparent;
  transition: all 0.15s ease-out; }

#left-col ul.uk-nav-default > li.padding-top {
  padding-top: 25px; }

#left-col ul.uk-nav-default li.uk-nav-header {
  padding: 20px 20px 8px 20px; }



.uk-light .uk-nav-default > li a,
.uk-light .uk-nav-default > li a:hover,
.uk-light .uk-nav-default > li a:focus,
.uk-light .uk-nav-default > li a:active,
.uk-light .uk-nav-default .uk-nav-sub a,
.uk-light .uk-nav-default .uk-nav-sub a:active,
.uk-light .uk-nav-default .uk-nav-sub a:hover,
.uk-light .uk-nav-default .uk-nav-sub a:focus {
  color: $gray-900; }


.largeMenu #left-col ul.uk-nav-default > li:hover:not(.uk-nav-header):not(.uk-nav-li-instance-picker),
#left-col ul.uk-nav-default > li.uk-open {
  //border-left: 2px solid $base-selection-background
  padding-left: 30px; }


.uk-light .uk-nav-default > li:not(:has(.instance-picker)) {
  opacity: 0.8; }


#left-col ul.uk-nav-default li .uk-nav-sub {
  padding-left: 34px; }


#left-col .uk-dropdown.user-drop {
  min-width: 160px;
  max-width: 160px;
  padding: 0.75rem; }

.multiselect__option--highlight {
  background-color: $blue-500 !important;

  span.link:hover {
    font-weight: 500; } }

#product-audit-container {
  .multiselect__input {
    margin-top: 2px; }
  .multiselect__placeholder {
    color: $gray-600 !important; }

  .multiselect__option--highlight {
    background-color: $blue-500 !important; }

  .multiselect, .multiselect__input, .multiselect__single {
    font-size: 14px !important; }

  .multiselect__tags {
    background: white;
    border: 1px solid #a0aec0 !important;
    border-radius: 3px !important; }
  .multiselect--active .multiselect__tags {
    border-top: 2px solid $blue-600 !important;
    border-left: 2px solid $blue-600 !important;
    border-right: 2px solid $blue-600 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important; }

  .multiselect.multiselect--above.multiselect--active .multiselect__tags {
    border-top: none !important;
    border-left: 2px solid $blue-600 !important;
    border-right: 2px solid $blue-600 !important;
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom: 2px solid $blue-600 !important; }

  .multiselect__content-wrapper {
    border-left: 2px solid $blue-600 !important;
    border-right: 2px solid $blue-600 !important;
    border-bottom: 2px solid $blue-600 !important; }

  .multiselect--above .multiselect__content-wrapper {
    border-top: 2px solid $blue-600 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: none !important; } }

/* OFFCANVAS */
.slide-left-enter-active {
  transition: all 0.2s ease-in; }

.slide-left-leave-active {
  transition: all 0.2s ease-out; }

.slide-left-enter, .slide-left-leave-to {}


.uk-offcanvas-bar h3,
.uk-offcanvas-bar h2,
.uk-offcanvas-bar h5,
.uk-offcanvas-bar h6,
.uk-offcanvas-bar h4,
.uk-offcanvas-bar .uk-h2,
.uk-offcanvas-bar .uk-button-text,
.uk-offcanvas-bar .uk-input,
.uk-offcanvas-bar .uk-input:focus,
.uk-offcanvas-bar .uk-select,
.uk-offcanvas-bar .uk-textarea:focus,
.uk-offcanvas-bar .uk-textarea,
.uk-offcanvas-bar .uk-table th,
.uk-offcanvas-bar a,
.uk-offcanvas-bar p,
.uk-offcanvas-bar a:hover,
.uk-offcanvas-bar label,
.uk-offcanvas-bar .uk-accordion-title {
  color: $gray-800; }

.uk-offcanvas a.uk-link {
  color: #2b6cb0; }

.uk-offcanvas a.uk-link:hover {
  color: #2c5282; }

//.uk-offcanvas-bar a:hover
//  font-weight: 500

.uk-offcanvas-bar .uk-select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A") !important; }


.uk-offcanvas-bar .uk-input::placeholder,
.uk-offcanvas-bar .uk-textarea::placeholder,
.uk-offcanvas-bar .uk-form-icon {
  color: $gray-600; }

.uk-offcanvas-bar .uk-table-divider > tr:not(:first-child), .uk-table-divider > :not(:first-child) > tr, .uk-table-divider > :first-child > tr:not(:first-child),
.uk-offcanvas-bar hr {
  border-top: 1px solid rgba(216, 216, 216, 0.38) !important; }

.uk-offcanvas-bar .uk-button-text:hover,
.uk-offcanvas-bar .uk-accordion-title:hover {
  color: $black; }

.uk-offcanvas-bar .uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-offcanvas-bar .uk-open>.uk-accordion-title::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E"); }


.uk-offcanvas-bar .uk-tab > .uk-active > a {
  color: #2d3748 !important;
  border-color: #1e87f0; }

.uk-offcanvas-bar .uk-tab a {
  color: #2d3748 !important; }

.uk-offcanvas-bar .uk-textarea {
  border: 1px solid $gray-500; }

.uk-offcanvas-bar .uk-text-muted {
  color: $gray-500 !important; }


.uk-offcanvas-bar .uk-button-primary,
.uk-offcanvas-bar .uk-label-primary,
.uk-overlay-primary .uk-button-primary {
  background-color: $blue-600;
  border: 1px solid $blue-600;
  color: $white; }

.uk-offcanvas-bar .uk-button-primary:hover,
.uk-overlay-primary .uk-button-primary:hover {
  background-color: #2c75b9;
  color: $white; }

.uk-offcanvas-bar .uk-button-primary:focus {
  background-color: #2c75b9;
  color: $white; }

.uk-offcanvas-bar .uk-button-default {
  background-color: $gray-100;
  color: $gray-800;
  box-shadow: none;
  border: 1px solid $gray-300; }

.uk-offcanvas-bar .uk-checkbox,
.uk-offcanvas-bar .uk-select {
  border-radius: $border-rounded-border-radius;
  background-color: $white;
  border: 1px solid $gray-500 !important; }

.uk-offcanvas-bar .uk-checkbox:checked {
  background-color: $blue-600 !important;
  border: 0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A"); }


.uk-offcanvas-bar .uk-radio:checked, .uk-offcanvas-bar .uk-radio:focus {
  background-color: $blue-600 !important;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E"); }

.uk-offcanvas-bar .uk-button-default:hover {
  border-color: $gray-400; }

.uk-offcanvas-page {
  overflow-y: overlay !important; }

.uk-offcanvas .uk-radio {
  background-color: white;
  border-color: $gray-500 !important; }

.uk-offcanvas .uk-input {
  background-color: white !important;
  border-color: $gray-500 !important; }

.uk-offcanvas .uk-input:focus {
  border: 2px solid $blue-600 !important; }

.uk-offcanvas-bar .uk-select:focus {
  border-color: $blue-600 !important;
  color: $gray-800; }

.uk-offcanvas-close {
  color: $gray-600 !important; }

.uk-offcanvas-close:hover {
  color: $gray-800 !important; }

svg.apexcharts-svg {
  overflow: visible !important; }

/* Drop arrow */
#left-col .uk-dropdown.user-drop:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $white;
  border-width: 4px;
  margin-left: -4px; }


.left-content-box {
  padding: 0.75rem 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  margin-bottom: -1px; }


.content-box-dark {
  background-color: rgba(0, 0, 0, 0.09); }


.text-light {
  font-weight: 200;
  font-size: 1.25rem; }


.bar-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  padding: 15px 0;
  background-color: $blue-900;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 3;
  bottom: 0;
  width: 240px; }


.left-logo {
  /* navbar height */
  max-width: 180px;
  max-height: 70px;
  padding: 10px 20px 10px 16px;
  font-weight: 900;
  color: #fff;
  font-size: 28px;
  letter-spacing: 1.6px;
  font-family: cabin; }


.smallMenu .left-logo {
  padding: 0;
  margin: 12px;
  max-width: 30px; }


.custom-logo {
  height: 32px; }


.profile-img {
  width: 60px;
  margin: 0 auto 10px auto;
  display: block; }


.panel-icons {
  margin: 0 4px; }


.uk-tooltip {
  background-color: white;
  color: $gray-800;
  box-shadow: 0 2px 12px rgba(50, 50, 50, 0.1); }


#content {
  padding: 30px 30px 30px;
  background-color: $gray-100;
  margin-left: 0;
  transition: margin 0.2s cubic-bezier(0.4, 0, 0.2, 1); }


.largeMenu #content {
  margin-left: 210px; }


.largeMenu .uk-unsaved-changes {
  left: 210px; }


.smallMenu #content {
  margin-left: 50px; }


.smallMenu .uk-unsaved-changes {
  left: 50px; }


.uk-unsaved-changes {
  padding: 10px 100px;
  z-index: $drop-z-index;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: $global-medium-box-shadow;
  top: 0;
  right: 0; }


.uk-child-width-1-8 > * {
  width: 12.5%; }


.uk-child-width-1-7 > * {
  width: 14.2857%; }


.uk-tablediv > * {
  padding: 15px; }


.uk-confirm-row-disabled {
  background: $gray-200; }


.uk-confirm-row-disabled > * {
  opacity: 0.3; }


.uk-confirm-row-disabled > div:last-of-type,
.uk-confirm-row-disabled > div:first-of-type,
.uk-confirm-row-disabled button:hover,
.uk-confirm-row-disabled button:active {
  opacity: 1; }


.uk-cursor-pointer {
  cursor: pointer; }

.uk-cursor-defult {
  cursor: default; }


/* DEV */
#devWarning {
  position: fixed;
  right: 10px;
  top: 0;
  height: 70px;
  width: 70px;
  color: white;
  font-weight: bold;
  background: #f0506e;
  line-height: 70px;
  text-align: center;
  box-shadow: 0 2px 8px #5a5a5a70;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid #cc324f;
  z-index: 999999; }


#content {
  /* equal to left-col width */
  margin-left: 240px; }

#top-head {
  /* equal to left-col width */
  left: 240px; }



#top-head [class*='uk-navbar-dropdown-bottom'] {
  margin-top: 0; }


/* Search nav */
.search-field {
  width: 140px;
  -webkit-transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }


.search-field:focus {
  width: 200px;
  border-color: $white !important; }


/* Smooth shadow transition */
.uk-card {
  -webkit-transition: box-shadow 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.55s cubic-bezier(0.4, 0, 0.2, 1); }

#left-col {
  width: 240px; }


/* UK-SWITCHER */
.uk-switch {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 35px; }


/* Hide default HTML checkbox */
.uk-switch input {
  display: none; }

/* Slider */
.uk-switch-slider {
  background-color: rgba(0,0,0,0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 17px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.07); }

/* Switch pointer */
.uk-switch-slider:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  transition-property: transform, box-shadow;
  transition-duration: .2s; }

/* Slider active color */
input:checked + .uk-switch-slider {
  background-color: #39f !important; }

/* Pointer active animation */
input:checked + .uk-switch-slider:before {
  transform: translateX(15px); }


/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
  background-color: #e0d2d4; }

input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important; }

.uk-switch-slider.uk-switch-on-off-disabled {
  background-color: #e5e5e5;
  cursor: not-allowed; }

input:checked + .uk-switch-slider.uk-switch-on-off-disabled {
  background-color: #e5e5e5 !important; }


/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0,0,0,0.22); }

.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0,0,0,0.22); }

input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2); }


/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255,255,255,0.22); }


/* END OF UK-SWITCHER */

.cursor-not-allowed {
  cursor: not-allowed !important; }

h1 .uk-input {
  padding-left: 5px;
  height: 50px;
  width: 500px; }


.uk-input, .uk-textarea {
  background: $white;
  border: 1px solid $gray-500;
  border-radius: $border-rounded-border-radius;
  color: $gray-900;
  transition: all 0.3s ease; }


.uk-input::placeholder, input::placeholder {
  color: $gray-600 !important;
  font-family: "Poppins", sans-serif !important; }


input.uk-form-width-xsmall {
  width: 60px; }


.uk-input:focus, .uk-select:focus, .uk-textarea:focus, .uk-offcanvas-bar .uk-textarea:focus {
  background: $white;
  border: 2px solid;
  border-color: $blue-600;
  box-shadow: $global-inset-small-box-shadow; }


.uk-form-stacked .uk-form-label {
  margin-bottom: 5px; }


.uk-input.uk-disabled {
  background: $white;
  border: none;
  color: $gray-500; }


.uk-input:disabled {
  background: $white;
  cursor: not-allowed; }


.uk-input.uk-disabled::placeholder,
.uk-input:disabled::placeholder {
  color: $gray-500; }


//ul.ti-tags
////height: 30px !important

.ti-input {
  height: 100%;
  border-radius: 2px;
  border: 1px solid $gray-500 !important; }


.ti-disabled {
  opacity: 0.5; }


.ti-disabled i {
  cursor: unset !important; }

div.vue-treeselect__control {
  height: 38px; }

div.match-input-height div.vue-treeselect__control {
  height: 40px; }

span.vue-treeselect__value-remove {
  border-left: unset; }

div.vue-treeselect__multi-value-item {
  border-radius: 3px; }

.uk-radio:checked {
  margin-top: 0; }


.uk-radio:checked {
  border: none; }


.vue-treeselect__control {
  border-radius: 2px !important;
  border: 1px solid $gray-500 !important; }


.ti-new-tag-input::placeholder {
  color: #8e8e8e;
  font-size: 12px; }


.vue-treeselect__placeholder {
  color: #8e8e8e !important; }


.ti-new-tag-input-wrapper {
  input {
    font-family: inherit; } }


.ti-input {
  border: none !important; }


.custom-input {
  border: 1px solid #a0aec0;
  border-radius: 3px;
  height: 40px;
  transition: all 0.3s ease;
  box-sizing: border-box;

  .uk-input {
    border: none;
    height: 36px; }

  .uk-input:focus {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none; } }

.custom-input-focus {
  border: 2px solid #3182ce;
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.08); }


@media screen and (max-height: 800px) {
  body, th, span {
    font-size: calc(7px + 0.4vw) !important; }

  h1 {
    font-size: calc(17px + 0.4vw) !important; }


  h2 {
    font-size: calc(15px + 0.4vw) !important; }

  h3 {
    font-size: calc(11px + 0.4vw) !important; }

  h4 {
    font-size: calc(9px + 0.4vw) !important; } }

.multiselect__spinner {
  right: 2px !important;
  top: 2px !important; }

.gray-overlay {
  display: block;
  position: fixed;
  z-index: 15;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(131, 131, 131, 0.4); }


.new-update-alert {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 6000;
  .uk-alert {
    padding-top: 0;
    padding-bottom: 0; } }


.default-image {
  opacity: 40%; }

.uk-width-xsmall {
  width: 60px; }

// in some cases icons are visible even if there is overlay element
.not-transform > svg {
  transform: unset !important;
  -webkit-transform: unset !important; }

.min-width-200 {
  min-width: 200px; }

.uk-tooltip.uk-active {
  //box-shadow: 0 14px 25px rgba(0,0,0,0.3) !important
  //-webkit-box-shadow: 0 14px 25px rgba(0,0,0,0.3) !important
  max-width: 250px !important;

  div {
    margin: 8px; } }


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease; }


.fade-enter-from,
.fade-leave-to {
  opacity: 0; }


.multiselect-disabled-options .multiselect-options {
  display: none !important; }

.small-multiselect {
  .multiselect-placeholder {
    font-size: 12px; }
  .multiselect-tags {
    margin-top: unset; }
  .multiselect-tag {
    margin: 2px; }

  .multiselect-clear:after, .multiselect-clear:before {
    top: 8px !important; }

  .multiselect-wrapper {
    min-height: 30px !important; } }

.multiselect-placeholder {
  font-size: 14px;
  font-family: inherit;
  padding-left: 10px !important;
  color: #8e8e8e !important; }

.multiselect-wrapper {
  border: 1px solid #a0aec0 !important;
  border-radius: 3px !important;
  min-height: 40px !important;
  background: #fff; }

.multiselect-tags {
  padding-left: 5px !important; }

.multiselect-dropdown {
  max-height: 300px; }

.multiselect-tag {
  background: #e3f2fd !important;
  color: #039be5 !important;
  font-weight: 400 !important;
  font-size: 12px !important;

  i:before {
    color: #039be5 !important; }
  i:hover:before {
    color: $red-500 !important; } }

.multiselect-wrapper:before {
  border-color: #ccc transparent transparent !important;
  color: #ccc !important; }

.multiselect-option {
  min-height: 40px; }

.multiselect-option, .multiselect-no-results {
  font-size: 12px !important; }

.is-tags .multiselect-search input {
  font-size: 12px !important; }

.multiselect-search:focus {
  outline: none; }

.multiselect-search input, .multiselect-search {
  width: 150px !important; }

.multiselect-clear {
  background: none !important; }


.v3dp__datepicker input {
  height: 30px; }

.uk-form-small .multiselect-wrapper {
  min-height: 30px !important; }

.uk-form-small .multiselect-search {
  height: 24px !important; }

.approval-system-picker {
  margin: 0;

  .option__title {
    padding-left: 5px;
    .uk-text-small {
      font-size: 0.75rem; } }


  .multiselect-single-label {
    font-size: 13px; }

  .multiselect-search {
    input {
      padding-left: 5px !important;
      font-size: 12px !important; } }

  .multiselect-option {
    color: #35495e !important;
    min-height: 40px; }

  .multiselect-option.is-pointed, .multiselect-option.is-selected.is-pointed {
    color: #fff !important;
    background: #039be5 !important;
    .uk-text-muted {
      color: #fff !important; } }
  .multiselect-option.is-selected {
    color: #fff !important;
    background: #46ade5 !important;

    .uk-text-muted {
      color: #fff !important; } } }

.fields-multiselect .multiselect-options {
  margin-left: 10px; }
//.rule-substitution-item .multiselect-search, .rule-substitution-item .multiselect-search input
//  width: 100% !important


.datepicker.uk-input {
  width: 80%; }

div.v3dp__clearable {
  left: unset;
  top: -7px; }

.hover-remove:hover,
.uk-table-hover tbody tr.hover-remove:hover,
.hover-remove:hover > *,
.uk-table-hover tbody tr.hover-remove:hover > td {
  background: unset; }
